<template>
  <div>
    <div
      v-if="exam"
      class="relative w-full max-w-3xl px-4 py-12 mx-auto"
    >
      <header class="pb-2 text-center">
        <h1 class="text-3xl font-medium leading-none text-center">
          {{ exam.name }}
        </h1>
        <h3 class="mt-6 mb-10 leading-none">
          <span v-if="exam.questionsCount">{{ exam.questionsCount }} questions</span>
          <span v-if="exam.questionsCount && exam.minutes"> | </span>
          <span v-if="exam.minutes">{{ exam.minutes }} minutes</span>
        </h3>
      </header>

      <ul
        class="mb-4 text-sm list-disc"
      >
        <li
          class="ml-4 disc"
        >
          Please allow the use of your webcam/camera (snapshots will be taken at regular intervals during the test) and do not leave full-screen mode. These measures are in place to ensure equality for all. If you decide not to enable your camera, you can still proceed with the test.
        </li>
      </ul>

      <ul
        v-if="exam.rules"
        class="text-sm list-disc"
      >
        <li
          v-for="(rule, index) in exam.rules"
          :key="index"
          class="mt-4 ml-4 disc first:mt-0"
        >
          {{ rule }}
        </li>
      </ul>

      <div
        v-if="exam.minutes && !organisationSpecialFeatures.includes('doNotAllowExtraTime')"
        class="mt-4 text-center"
      >
        <button
          v-if="!showExtraTimeOption"
          class="text-sm underline"
          @click="showExtraTimeOption = true"
        >
          Apply for extra time
        </button>
        <label
          v-else-if="showExtraTimeOption"
          class="text-sm"
        >
          <input
            :value="extraTime"
            type="checkbox"
            @input="setExtraTime($event.target.checked)"
          >
          I have been given permission to be provided with extra time
        </label>
      </div>

      <div class="text-center">
        <BaseButton
          v-if="canStartExam(exam.slug)"
          class="mx-auto mt-6 text-lg"
          @click="$emit('begin-test', true)"
        >
          Begin test
          <template slot="iconRight">
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 42 42"
            >
              <Play />
            </Icon>
          </template>
        </BaseButton>
        <div v-else>
          <BaseButton
            class="mx-auto mt-6 text-lg"
            disabled
          >
            Incomplete
            <template slot="iconRight">
              <Icon
                width="16px"
                height="16px"
                view-box="0 0 42 42"
                class="ml-2"
              >
                <Play />
              </Icon>
            </template>
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      v-else
      class="p-16"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import Icon from '@components/Icons/Icon'
import Play from '@components/Icons/Play'

import { mapGetters } from 'vuex'

export default {
  components: {
    Loader,
    Icon,
    Play
  },

  props: {
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      showExtraTimeOption: false
    }
  },

  computed: {
    ...mapGetters({
      canStartExam: 'candidates/canStartExam',
      extraTime: 'attempt/extraTime',
      organisationSpecialFeatures: 'candidates/organisationSpecialFeatures'
    })
  },

  methods: {
    /**
     * Set the extra time status
     *
     * @param {Boolean} checked
     */
    setExtraTime(checked) {
      this.$store.commit('attempt/setExtraTime', checked)
    }
  }
}
</script>

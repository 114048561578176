export const antiCheating = {
  data() {
    return {
      leaveAssessmentWindowCount: 0,
      webcam: false,
      hasExitedFullScreen: false
    }
  },

  computed: {
    /**
     * @return {Object}
     */
    antiCheatData() {
      return {
        leaveAssessmentWindowCount: this.leaveAssessmentWindowCount,
        hasExitedFullScreen: this.hasExitedFullScreen,
        webcam: this.webcam
      }
    }
  },

  created() {
    document.addEventListener('fullscreenchange', (event) => {
      if (!document.fullscreenElement) {
        this.hasExitedFullScreen = true
      }
    })
  },

  mounted() {
    this.createCameraStream()
  },

  beforeDestroy() {
    this.stopCameraStream()
  },

  methods: {
    mouseLeaveAssessmentsWindow() {
      if (this.assessmentStarted) {
        this.leaveAssessmentWindowCount++
      }
    },

    createCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.$refs.camera.srcObject = stream
          this.webcam = true
        })
        .catch(error => {
          if (error && error.message !== 'Permission denied') {
            console.error(error)
          }
        })
    },

    stopCameraStream() {
      if (!this.webcam) {
        return
      }
      let tracks = this.$refs.camera.srcObject.getTracks()

      // Tracks are returned as an array. We know we only have one, so we can stop it with:
      tracks[0].stop()
      this.webcam = false
    },

    requestFullScreenAssessment() {
      const container = document.getElementById('app')

      if (container.requestFullscreen) {
        container.requestFullscreen()
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen()
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen()
      } else if (container.msRequestFullscreen) {
        container.msRequestFullscreen()
      }
    }
  }
}
